.feed {
    flex: 8;
    position: sticky;
    top: 0;
    overflow-y: scroll;
    height: calc(100vh - 74px);
}

.feed::-webkit-scrollbar {
    display: none;
}

.feedContainer {
    width: 55%;
    margin: 0 auto;
}

@media screen and (max-width: 1100px) {
    .feedContainer {
        width: unset;
        margin-right: 64px;
    }
}

@media screen and (max-width: 900px) {
    .feedContainer {
        margin-right: 0;
    }
}
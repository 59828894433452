* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif, serif;
}
body {
  margin: 0;
  padding: 0;
}
:root {
  --fs-100: 12px;
  --fs-200: 14px;
  --fs-300: 16px;
  --fs-400: 18px;
  --fs-500: 20px;
  --fs-600: 24px;
  --fs-700: 30px;
  --fs-800: 34px;
  --fs-900: 42px;
  --blue-clr: #0866ff;
  --dark-blue-clr: #025ae7;
  --light-blue-clr: #ecf3ff;
  --green-clr: #42b72a;
  --dark-green-clr: #3aa324;
  --red-clr: #f35369;
  --yellow-clr: #f5c33b;
  --gray-clr: #f0f2f5;
  --dark-gray-clr: #e4e2e2;
  --gray-txt-clr: #777777;
  --white-clr: #ffffff;
  --black-clr: #000000;
}
a {
  text-decoration: none;
  color: inherit;
}
hr {
  border: none;
  border-top: 1px solid var(--dark-gray-clr);
}
input {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
}
input:focus {
  outline: none;
}
button, input[type="checkbox"], select {
  cursor: pointer;
}
button:disabled {
  background-color: var(--dark-gray-clr) !important;
  cursor: not-allowed;
}
.green {
  color: var(--green-clr);
}
.blue {
  color: var(--blue-clr);
}
.yellow {
  color: var(--yellow-clr);
}
.red {
  color: var(--red-clr);
}
.gray {
  color: var(--dark-gray-clr);
}
.mb-16 {
  margin-bottom: 16px;
}
.pd-8 {
  padding: 8px;
}
.bs {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.hidden {
  display: none;
}

/* Form */
.formError {
  border: 1px solid red !important;
}
.formControl {
  margin-bottom: 12px;
}
.formControlFlex {
  display: flex;
  gap: 10px;
}
.formControlCheckbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--dark-gray-clr);
  border-radius: 4px;
  width: 100%;
  padding: 8px;
}
.formControlCheckbox label {
  font-size: var(--fs-100);
}
.formControlCheckboxInput {
  height: 12px !important;
  width: 12px !important;
}
.formControlLabel {
  display: block;
  font-size: var(--fs-100);
  margin-bottom: 4px;
}

.card {
  background-color: var(--white-clr);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

/* profileLinks */
.profileLinks {
  margin: 4px 0;
}
.profileLink {
  color: var(--gray-txt-clr);
  font-size: var(--fs-200);
  font-weight: 600;
  padding: 16px;
  border-radius: 8px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.profileLink:hover {
  background-color: var(--gray-clr);
}
.profileLink.active {
  color: var(--blue-clr);
}
.profileLink.active::after {
  content: '';
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -4px;
  left: 0;
  background-color: var(--blue-clr);
}

@media screen and (max-width: 1100px) {
  :root {
    --fs-100: 10px;
    --fs-200: 12px;
    --fs-300: 14px;
    --fs-400: 16px;
    --fs-500: 18px;
    --fs-600: 22px;
    --fs-700: 28px;
    --fs-800: 32px;
    --fs-900: 40px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --fs-100: 10px;
    --fs-200: 12px;
    --fs-300: 14px;
    --fs-400: 16px;
    --fs-500: 18px;
    --fs-600: 20px;
    --fs-700: 26px;
    --fs-800: 30px;
    --fs-900: 38px;
  }
}

.topbarAccount {
  position: absolute;
  top: 60px;
  right: 0;
  max-width: 360px;
  width: 100%;
  display: none;
}
.topbarAccount.active {
  display: block;
}
.topbarAccount hr {
  border: none;
  border-top: 1px solid var(--dark-gray-clr);
}
.topbarAccountProfileUser {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 8px;
}
.topbarAccountProfileUserImage {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}
.topbarAccountProfileUserImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.topbarAccountProfileUsername {
  font-size: var(--fs-300);
  font-weight: 500;
}
.topbarAccountProfileUserText {
  color: var(--blue-clr);
  font-size: var(--fs-200);
  font-weight: 500;
  margin: 4px;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
}
.topbarAccountMenuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 8px;
}
.topbarAccountMenuItem:hover,
.topbarAccountProfileUser:hover,
.topbarAccountProfileUserText:hover {
  background-color: var(--gray-clr);
}
.topbarAccountMenuItemLeft {
  display: flex;
  align-items: center;
}
.topbarAccountMenuItemLeftIcon {
  background-color: var(--dark-gray-clr);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: grid;
  place-items: center;
  margin-right: 12px;
}
.topbarAccountMenuItemLeftText {
  font-size: var(--fs-200);
  font-weight: 500;
}
.topbarAccountMenuItemRightIcon {
  color: var(--gray-txt-clr);
}

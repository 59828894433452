.profileIntroTop > p {
  text-align: center;
  font-size: var(--fs-200);
}
.profileIntroBottomItem {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.profileIntroBottomItemIcon {
  color: var(--gray-txt-clr);
  margin-right: 8px;
}
.profileIntroBottomItemText {
  font-size: var(--fs-200);
  margin: 0;
}
.profileIntroBottomItemTextBold {
  font-weight: 600;
}

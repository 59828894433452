.messengerBottom {
    position: fixed;
    bottom: 0;
    right: 80px;
    background-color: var(--white-clr);
    height: 460px;
    width: 330px;
    padding: 0 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 2px 2px 8px rgb(202, 202, 202);
    display: none;
    z-index: 1000;
    overflow: hidden;
}
.messengerBottomContainer {
    height: 100%;
    overflow-y: scroll;
}
.messengerBottom.active {
    display: block;
}
.messengerBottom.active span, .messengerBottom.active button {
    color: var(--blue-clr);
}
.messengerBottomTop {
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--gray-clr);
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--white-clr);
    z-index: 1000;
}
.messengerBottomTopLeft {
    display: flex;
    align-items: center;
}
.messengerBottomTopLeftImg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;
}
.messengerBottomTopLeftImg > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.messengerBottomTopLeftUsername {
    font-size: var(--fs-200);
    font-weight: 600;
}
.messengerBottomTopLeftButton {
    border-radius: 50%;
    display: grid;
    place-items: center;
    color: var(--gray-txt-clr);
    cursor: pointer;
}
.messengerBottomTopLeftButton > * {
    font-size: var(--fs-400);
}
.messengerBottomTopRight {
    display: flex;
    align-items: center;
    gap: 4px;
}
.messengerBottomTopRightButton {
    color: var(--gray-txt-clr);
    cursor: pointer;
    border-radius: 50%;
    display: grid;
    place-items: center;
    padding: 4px;
}
.messengerBottomTopRightButton:hover {
    background-color: var(--gray-clr);
}
.messengerBottomTopRightButton > * {
    font-size: var(--fs-500) !important;
}
.messengerBottomMessages {
    padding: 96px 0 80px 0;
}
.messengerBottomMessagesTop {
    text-align: center;
    margin-bottom: 32px;
}
.messengerBottomMessagesTopImg {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}
.messengerBottomMessagesTopUsername {
    margin: 0 0 8px 0;
    font-size: var(--fs-300);
    font-weight: 600;
}
.messengerBottomMessagesTopText {
    margin: 0;
    font-size: var(--fs-100);
    color: var(--gray-txt-clr);
}
.messengerBottomMessagesItemDate {
    font-size: var(--fs-100);
    color: var(--gray-txt-clr);
    text-align: center;
    display: block;
    margin-bottom: 22px;
}
.messengerBottomMessagesItemSender {
    display: flex;
}
.messengerBottomMessagesItemSenderImg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    align-self: flex-end;
    margin-right: 8px;
}
.messengerBottomMessagesItemSenderText {
    margin: 0 0 2px 0;
    font-size: var(--fs-200);
    background-color: var(--gray-clr);
    padding: 8px;
    border-radius: 16px;
    width: max-content;
}
.messengerBottomMessagesItemSenderImg > img, .messengerBottomMessagesItemReceiverImg > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.messengerBottomMessagesItemReceiver {
    width: max-content;
    margin-left: auto;
    position: relative;
}
.messengerBottomMessagesItemReceiverText {
    margin: 0 0 2px 0;
    font-size: var(--fs-200);
    background-color: var(--blue-clr);
    color: var(--white-clr);
    border-radius: 16px;
    padding: 8px;
}
.messengerBottomMessagesItemReceiverImg { 
    width: 15px;
    height: 15px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    bottom: -16px;
    right: 0;
}
.messengerBottomActions {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--white-clr);
}
.messengerBottomActionsInputWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--gray-clr);
    border-radius: 16px;
    padding-right: 4px;
    margin: 0 4px;
}
.messengerBottomActionsInputWrapper > input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    margin: 0 8px 0 8px;
    padding: 12px 0;
    text-wrap: wrap;
}
.messengerBottomActionsBtn {
    display: grid;
    place-items: center;
    cursor: pointer;
    border-radius: 50%;
    padding: 4px;
    border: none;
    background-color: inherit;
}
.messengerBottomActionsBtn:disabled {
    background-color: unset !important;
    color: var(--gray-txt-clr) !important;
}
.messengerBottomActionsBtn:hover {
    background-color: var(--gray-clr);
}

@media screen and (max-width: 480px) {
    .messengerBottom {
        right: 16px;
        width: calc(100vw - 32px);
    }
}
.createStory {
    background-color: white;
    border-radius: 8px;
}
.createStoryContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.createStoryContainer:hover {
    background-color: var(--gray-clr);
}
.createStoryLeft {
    margin-right: 16px;
}
.createStoryIcon {
    color: var(--blue-clr) !important;
    background-color: #e6efff;
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    border-radius: 50%;
}
.createStoryTitle {
    font-size: var(--fs-300);
    font-weight: 600;
    margin: 0 0 2px 0;
}
.createStoryDesc {
    font-size: var(--fs-200);
    margin: 0;
    color: gray;
}
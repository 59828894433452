.topbarMessenger {
  position: absolute;
  top: 60px;
  right: 0;
  max-width: 360px;
  width: 100%;
  display: none;
}
.topbarMessenger.active {
    display: block;
}
.topbarMessengerTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}
.topbarMessengerTopText {
    font-size: var(--fs-600);
    font-weight: 600;
    margin: 0;
}
.topbarMessengerTopButtons {
    display: flex;
    align-items: center;
    gap: 8px;
}
.topbarMessengerTopButton {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;
    color: var(--gray-txt-clr);
    cursor: pointer;
}
.topbarMessengerTopButton:hover {
    background-color: var(--gray-clr);
}
.topbarMessengerTopButton > * {
    font-size: var(--fs-500) !important;
}
.topbarMessengerInputWrapper {
    background-color: var(--gray-clr);
    border-radius: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.topbarMessengerInputWrapper > input {
    border: none;
    background-color: transparent;
    font-size: var(--fs-200);
    padding: 8px 0;
}
.topbarMessengerInputIcon {
    margin: 4px 4px 0 4px;
    color: var(--gray-txt-clr);
}
.topbarMessengerInputIcon > *{
    font-size: var(--fs-600) !important;
}
.topbarMessengerConversation {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    padding: 8px;
}
.topbarMessengerConversation:hover {
    background-color: var(--gray-clr);
}
.topbarMessengerConversationImage {
    width: 60px;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
}
.topbarMessengerConversationImage >img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.topbarMessengerConversationRightBottom {
    display: flex;
    align-items: center;
}
.topbarMessengerConversationUsername {
    margin: 0;
    font-size: var(--fs-200);
    font-weight: 500;
}
.topbarMessengerConversationMessage, .topbarMessengerConversationDate {
    margin: 0;
    font-size: var(--fs-100);
    color: var(--gray-txt-clr);
}
.topbarMessengerConversationMessage {
    max-width: 220px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 8px;
}
.topbarMessenger hr {
    margin-left: -16px;
    margin-right: -16px;
}
.topbarMessengerBottomBtn {
    margin: 16px auto 8px auto;
    text-align: center;
    font-size: var(--fs-200);
    color: var(--blue-clr);
    font-weight: 500;
    position: relative;
    width: max-content;
    cursor: pointer;
}
.topbarMessengerBottomBtn:hover::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--blue-clr);
    position: absolute;
    bottom: 2px;
    left: 0;
}
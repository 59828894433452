.postCommentsItem {
  display: flex;
  margin: 8px 0;
  position: relative;
  width: min-content;
}
.postCommentsItemLeft {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}
.postCommentsItemLeft > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.postCommentsItemRight {
  position: relative;
}
.postCommentsItemRightTop {
  background-color: var(--gray-clr);
  border-radius: 16px;
  padding: 8px 12px;
  position: relative;
  max-width: 662px;
  width: 100%;
}
.postCommentsItemUsername {
  font-size: var(--fs-100);
  font-weight: 600;
  margin: 0;
}
.postCommentsItemText {
  font-size: var(--fs-200);
  margin: 0;
  word-wrap: break-word;
}
.postCommentsItemRightBottom {
  display: flex;
  padding: 0 12px;
}
.postCommentsItemRightBottom span {
  font-size: var(--fs-100);
  margin-right: 8px;
}
.postCommentsItemDate {
  width: max-content;
}
.postCommentsItemAction {
  font-weight: 600;
  cursor: pointer;
  position: relative;
}
.postCommentsItemAction:hover::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--black-clr);
}
.postCommentsItemLikesCount {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: var(--white-clr);
  box-shadow: 2px 2px 2px lightgrey;
  height: min-content;
  border-radius: 12px;
  padding: 2px 4px;
}
.postCommentsItemLikesCountIcon {
  background-color: var(--blue-clr);
  color: var(--white-clr);
  border-radius: 50%;
  height: 18px;
  width: 18px;
  display: grid;
  place-items: center;
  margin-right: 4px;
}
.postCommentsItemLikesCountText {
  margin: 0;
  font-size: var(--fs-100);
}
.postCommentsItemLikesCountIcon > * {
  font-size: var(--fs-100) !important;
}
.postCommentsItemOptions {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: grid;
  place-items: center;
  margin: 0 8px 0 8px;
  align-self: center;
  cursor: pointer;
}
.postCommentsItemOptions:hover {
  background-color: var(--gray-clr);
}
.postCommentsItemOptions * {
  font-size: var(--fs-300) !important;
  pointer-events: none;
}
.commentOptions {
  position: absolute;
  left: 0;
  bottom: -64px;
  height: 80px;
  background-color: var(--white-clr);
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 5px 30px -7px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 5px 30px -7px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 5px 30px -7px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.commentOptions button {
  background-color: var(--white-clr);
  border: none;
  text-align: left;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  height: 100%;
}
.commentOptions button:hover {
  background-color: var(--gray-clr);
}


.deleteContainer {
  width: 600px;
}
.deleteHeader {
  margin-left: -8px;
  margin-right: -8px;
  position: relative;
  border-bottom: 1px solid var(--dark-gray-clr);
}
.deleteHeaderText {
  text-align: center;
  font-weight: 600;
  margin: 0;
  padding: 8px 0 16px 0;
}
.deleteClose {
  position: absolute;
  top: 4px;
  right: 8px;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  background-color: var(--gray-clr);
}
.deleteMain {
  margin-bottom: 32px;
}
.deleteMainText {
  font-size: var(--fs-200);
}
.deleteActions {
  display: flex;
  justify-content: flex-end;
}
.deleteActions button {
  border-radius: 4px;
  height: var(--fs-800);
  cursor: pointer;
}
.deleteActionsSecondary {
  margin-right: 8px;
  border: none;
  color: var(--blue-clr);
  background-color: transparent;
  padding-inline: var(--fs-300);
}
.deleteActionsSecondary:hover {
  background-color: var(--gray-clr);
}
.deleteActionsPrimary {
  background-color: var(--blue-clr);
  color: var(--white-clr);
  border: none;
  padding-inline: var(--fs-800);
}
.leftbar {
    flex: 2;
}
.leftbarItem {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    border-radius: 8px;
    cursor: pointer;
    height: 50px;
}
.leftbarItem:first-child > .leftbarItemIcon {
    border-radius: 50%;
    overflow: hidden;
}
.leftbarItem:hover {
    background-color: var(--dark-gray-clr);
}
.leftbarItemIcon {
   width: 32px;
   height: 32px;
   border-radius: 50%;
   display: grid;
   place-items: center;
   margin-right: 16px;
}
.leftbarItemIcon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.leftbarItemTitle {
    font-size: var(--fs-200);
    font-weight: 500;
}

@media screen and (max-width: 1100px) {
    .leftbar {
        display: none;
    }
}
.login {
  background-color: var(--gray-clr);
  height: 100vh;
}

.loginContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.loginLeft,
.loginRight {
  flex: 1;
}

.loginTitle {
  font-size: 48px;
  color: var(--blue-clr);
  font-weight: 700;
  margin-bottom: 12px;
}

.loginText {
  font-family: sans-serif;
  font-size: var(--fs-600);
  font-weight: 500;
}

.loginFormContainer{
  max-width: 380px;
}

.loginForm input {
  width: 100%;
  background-color: var(--white-clr);
  border: 1px solid var(--dark-gray-clr);
  height: 48px;
  border-radius: 8px;
  padding-left: 16px;
  font-size: var(--fs-200);
  font-weight: 500;
}

.loginForm input:focus {
  border: 1px solid var(--blue-clr);
}

.loginForm .loginBtn {
  width: 100%;
  height: 48px;
  background-color: var(--blue-clr);
  color: var(--white-clr);
  border-radius: 8px;
  border: none;
  font-size: var(--fs-400);
  font-weight: 500;
  cursor: pointer;
}
.loginBtn:hover {
  background-color: var(--dark-blue-clr);
}
.loginForm .forgotPassword {
  text-align: center;
  color: var(--blue-clr);
  font-size: var(--fs-200);
  font-weight: 500;
  cursor: pointer;
}
.loginForm hr {
  border: none;
  border-top: 1px var(--dark-gray-clr) solid;
  height: 1px;
  margin-bottom: 26px;
}
.loginForm .createAccountBtn {
  background-color: var(--green-clr);
  color: var(--white-clr);
  border: none;
  border-radius: 8px;
  height: 48px;
  font-size: var(--fs-400);
  padding: 0 16px;
  display: block;
  margin: 16px auto;
  cursor: pointer;
  transition: background-color 0.125s ease;
}
.loginForm .createAccountBtn:hover {
  background-color: var(--dark-green-clr);
}

@media screen and (max-width: 768px) {
  .loginLeft {
    display: none;
  }

  .loginFormContainer {
   margin: 0 auto;
  }
}
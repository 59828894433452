.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.125);
  display: grid;
  place-items: center;
  overflow-y: scroll;
  z-index: 1000;
}

.modalContainer {
  margin: 64px 0;
}

.modal .post {
  box-shadow: none;
}

body:has(.modal){
  overflow: hidden;
}

.editProfile {
  width: 700px;
}
.editProfileTop {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--dark-gray-clr);
}
.editProfileTopText {
  margin: 0 0 16px 0;
}
.editProfileClose {
  cursor: pointer;
  background-color: var(--gray-clr);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
}
.editProfileClose:hover {
  background-color: var(--dark-gray-clr);
}
.editProfileSectionTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.editProfileImage {
  border-radius: 50%;
  width: 170px;
  height: 170px;
  margin: 0 auto;
  overflow: hidden;
}
.editProfileImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.editProfileCoverImage {
  width: 500px;
  height: 200px;
  border-radius: 8px;
  margin: 0 auto;
  overflow: hidden;
}
.editProfileCoverImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.editProfileSectionTop {
  margin-bottom: 8px;
}
.editProfileSectionText {
  font-size: var(--fs-400);
  font-weight: 600;
}
.editProfileSectionBtn {
  background: none;
  color: var(--blue-clr);
  border: none;
  font-size: var(--fs-200);
  cursor: pointer;
}
.editProfileSectionBtn:hover {
  color: var(--dark-blue-clr);
}
.editProfileBioTextarea {
  display: block;
  border: none;
  font-size: var(--fs-300);
  width: 200px;
  height: 22px;
  margin: 0 auto;
  background-color: none;
  outline: none;
  text-align: center;
}
.editProfileBioTextarea:focus {
  border: 2px solid var(--blue-clr);
}
.editProfileIntroItem {
  display: flex;
  align-items: center;
}
.editProfileIntroItemIcon {
  margin-right: 8px;
  color: var(--gray-txt-clr);
}
.editProfileIntroItemText {
  font-size: var(--fs-200);
}
.editProfileIntroItemInput {
  border: none;
  padding: 2px;
  width: 200px;
  font-size: var(--fs-100);
}
.editProfileBtn {
  background-color: var(--light-blue-clr);
  color: var(--blue-clr);
  width: 100%;
  border-radius: 4px;
  border: none;
  padding: 12px 0;
  cursor: pointer;
  font-size: var(--fs-300);
  font-weight: 600;
}

.profilePage {
  background-color: var(--gray-clr);
  padding: 0 16px;
}
.profilePageContainer {
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  padding: 16px 0;
  display: flex;
  gap: 16px;
}
.profileFeedLeft {
  flex: 5;
}
.profileFeedLeftIntroTop > h3 {
  margin: 0;
  font-size: var(--fs-500);
}
.profileFeedRight {
  flex: 7;
}
.profileFeedRightHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profileFeedRightHeaderContainer h3 {
  margin: 0;
}
.profileFeedRightPosts {
  position: sticky;
  top: 0;
  overflow-y: scroll;
  height: calc(100vh - 74px);
}
.profileFeedRightPosts::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 768px) {
  .profileFeedLeft {
    display: none;
  }
}
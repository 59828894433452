.profileInfoContainer {
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
}

.profileInfo {
  margin: 0 16px;
}

.profileInfoContainer hr {
  margin: 0;
}
.profileImages {
  max-width: 1250px;
  width: 100%;
  height: 460px;
  margin: 0 auto;
  position: relative;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.profileCoverImage {
  height: 100%;
  width: 100%;
}

.profileCoverImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.profileUserImage {
  width: 170px;
  height: 170px;
  position: absolute;
  bottom: -85px;
  left: 32px;
  border-radius: 50%;
  border: 4px solid var(--white-clr);
  overflow: hidden;
}

.profileUserImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profileInfoTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 8px 0;
}

.profileInfoTopLeft {
  display: flex;
  align-items: center;
}

.profileInfoTopLeftImagePlaceholder {
  width: 165px;
  height: 65px;
}

.profileInfoTopLeftUsername > h1 {
  font-weight: 700;
  font-size: var(--fs-700);
  margin: 0 0 0 22px;
}

.profileInfoTopRight {
  display: flex;
}

.profileInfoTopRightBtn {
  background-color: var(--dark-gray-clr);
  height: 36px;
  border: none;
  border-radius: 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  cursor: pointer;
  position: relative;
}
.profileInfoTopRightBtn:first-child {
  background-color: var(--blue-clr);
}
.profileInfoTopRightBtn:hover:not(:first-child),
.profileInfoBottomRightBtn:hover {
  background-color: #dadada;
}
.profileInfoTopRightBtn.blue {
  color: var(--white-clr);
}
.profileInfoTopRightBtn.blue:hover {
  background-color: #0050cf;
}
.profileInfoTopRightBtnIcon {
  height: 24px;
  margin-right: 4px;
}
.profileInfoTopRightBtnIcon:last-child {
  margin-right: 0;
}
.profileInfoTopRightBtnText {
  font-size: var(--fs-200);
  font-weight: 500;
}
.friendRequestRespondModal {
  position: absolute;
  bottom: -110px;
  left: 0;
  width: 300px;
  z-index: 1000;
  background-color: var(--white-clr);
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px gray;
}

.friendRequestRespondModal button {
    display: block;
    width: 100%;
    background-color: transparent;
    border: none;
    text-align: left;
    padding: 12px 0;
    font-weight: 500;
    border-radius: 4px;
}
.friendRequestRespondModal button:hover {
  background-color: var(--gray-clr);
}
.profileInfoBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profileInfoBottomRightBtn {
  background-color: var(--dark-gray-clr);
  height: 36px;
  border: none;
  border-radius: 8px;
  margin-left: 8px;
  display: grid;
  place-items: center;
  padding: 0 8px;
  cursor: pointer;
}

.profileInfoFriendRequest {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--gray-clr);
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
}
.profileInfoFriendRequestMessage {
  margin: 0;
  font-size: var(--fs-300);
  font-weight: 500;
}
.profileInfoFriendRequestActions button {
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
}
.profileInfoFriendRequestAccept {
  margin-right: 8px;
  background-color: var(--blue-clr);
  color: white;
}
.profileInfoFriendRequestDecline {
  background-color: var(--dark-gray-clr);
}

@media screen and (max-width: 768px) {
  .profileInfoTopRight {
    display: none;
  }
}
.profilePhotosBottom {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  align-items: stretch;
  border-radius: 8px;
}

.profilePhotosPhoto {
  aspect-ratio: 1;
}

.profilePhotosPhoto img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

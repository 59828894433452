.topbar {
  height: 58px;
  background-color: var(--white-clr);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999999;
}
.topbarContainer {
  min-height: 100%;
  margin: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.topbarLeft {
  display: flex;
  align-items: center;
  padding: 8px 0;
  position: relative;
}
.topbarLogo {
  height: 42px;
  width: 42px;
  margin-right: 8px;
  cursor: pointer;
}
.topbarLogo > img {
  width: 100%;
  height: 100%;
}
.topbarInputWrapperBack {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  color: var(--gray-txt-clr);
  margin-right: 8px;
  cursor: pointer;
}
.topbarInputWrapperBack:hover {
  background-color: var(--dark-gray-clr);
}
.topbarInputWrapper {
  overflow: hidden;
  padding: 0 8px;
  height: 40px;
  max-width: 240px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 22px;
  background-color: var(--gray-clr);
}

.topbarInputWrapper.active {
  max-width: 252px;
  width: 100%;
}

.topbarInputWrapper .searchIcon {
  background-color: var(--gray-clr);
  border-radius: 50%;
}
.topbarInputWrapper > label {
  margin-right: 4px;
  font-size: 0px;
}
.topbarInputWrapper > input {
  border: none;
  width: 100%;
  background-color: inherit;
  font-size: var(--fs-200);
}
.topbarCenter {
  display: flex;
  gap: 8px;
  height: 100%;
}
.topbarCenterItem {
  cursor: pointer;
  border-radius: 8px;
  display: grid;
  place-items: center;
  width: 120px;
  height: 50px;
  position: relative;
}
.topbarCenterItem > * {
  font-size: var(--fs-600) !important;
}
.topbarCenterItem.active {
  color: var(--blue-clr);
}
.topbarCenterItem.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--blue-clr);
}
.topbarCenterItem:hover:not(.active) {
  background-color: var(--gray-clr);
}
.topbarRight {
  padding: 8px 0;
  display: flex;
  gap: 8px;
  align-items: center;
}
.topbarRightItem {
  background-color: var(--gray-clr);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.topbarRightItem.active {
  color: var(--blue-clr);
}
.topbarRightItem > * {
  font-size: var(--fs-600) !important;
  pointer-events: none;
}
.topbarRightItem:hover {
  background-color: var(--dark-gray-clr);
}

.topbarSearchBoxLoading {
  text-align: center;
  color: var(--gray-txt-clr);
}
.topbarSearchBox {
  width: 300px;
  position: absolute;
  top: 56px;
  left: 0;
  background-color: white;
  box-shadow: 0 8px 8px lightgray;
  padding: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.topbarSearchBoxItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 0;
}
.topbarSearchBoxItem:hover {
  background-color: var(--gray-clr);
  border-radius: 8px;
}

.topbarSearchBoxUserImage {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
}

.topbarSearchBoxUserImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.topbarSearchBoxUserName {
  font-size: var(--fs-200);
  text-transform: lowercase;
}

@media screen and (max-width: 1100px) {
  .topbarInputWrapper > input {
    display: none;
  }

  .topbarInputWrapper.active > input {
    display: block;
  }

  .topbarInputWrapper > label {
    margin-right: 0;
  }

  .topbarInputWrapper {
    width: min-content;
  }

  .topbarCenterItem {
    width: 90px;
  }

  .topbarRightItem {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 768px) {
  .topbarCenter {
    display: none;
  }

  .topbarAccount {
    width: 100%;
  }
}

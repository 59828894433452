.addPost {
  background-color: var(--white-clr);
  border-radius: 8px;
}

.addPostTop {
  display: flex;
}

.addPostTopIcon {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.addPostTopIcon:hover {
  filter: grayscale(25%);
}
.addPostTopIcon > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.addPostTop > input {
  background-color: var(--gray-clr);
  border: none;
  border-radius: 16px;
  padding-left: 12px;
  font-size: var(--fs-200);
  width: calc(100% - 40px);
  cursor: pointer;
}
.addPostLine {
  border: 1px solid var(--gray-clr);
}
.addPostBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addPostBottomItem {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.addPostBottomItem:hover {
  background-color: var(--gray-clr);
}
.addPostBottomItemIcon {
  margin-right: 8px;
}
.addPostVideoIcon {
  color: var(--red-clr);
}
.addPostMediaIcon {
  color: var(--green-clr);
}
.addPostSmileIcon {
  color: var(--yellow-clr);
}
.addPostItemDesc {
  font-size: var(--fs-200);
  font-weight: 600;
  color: var(--gray-txt-clr);
  margin: 0;
}

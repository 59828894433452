.addPostCardContainer {
    width: 500px;
    position: relative;
}

.addPostCardTitle {
    text-align: center;
    font-size: var(--fs-400);
    font-weight: 600;
    margin: 0 0 16px 0;
}

.addPostCardClose {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    background-color: var(--gray-clr);
    border-radius: 50%;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.addPostCardClose:hover, .addPostCardImageClose:hover {
    background-color: var(--dark-gray-clr);
}

.addPostCardContainer hr {
    margin-left: -16px;
    margin-right: -16px;
    border: none;
    border-top: 1px solid var(--dark-gray-clr);
}

.addPostCardUser {
    display: flex;
    margin-bottom: 16px;
}

.addPostCardUserImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 4px;
}

.addPostCardUserImage > img {
    width: 100%;
    height: 100%;
}

.addPostCardUsername {
    font-size: var(--fs-200);
    font-weight: 600;
    margin: 0;
    margin-bottom: 4px;
}

.addPostCardVisibility {
    display: flex;
    justify-content: space-between;
    background-color: var(--dark-gray-clr);
    padding: 0;
    border-radius: 4px;
    padding: 2px 4px;
}

.addPostCardVisibilityText {
    font-size: var(--fs-100);
    margin: 0;
}

.addPostCardVisibilityIcon * {
    font-size: var(--fs-200) !important;
}

.addPostCardInputWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.addPostCardInputWrapper > input{
    width: 80%;
    height: 32px;
    border: none;
}

.addPostCardEmotionIcon{
    color: var(--gray-txt-clr);
}

.addPostCardImageWrapper {
    border-radius: 8px;
    padding: 8px;
    border: 1px solid var(--dark-gray-clr);
    position: relative;
    margin-bottom: 16px;
}

.addPostCardImage {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.addPostCardImage > img, .addPostCardImage > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.addPostCardImageClose {
    position: absolute;
    top: 16px;
    right: 16px;
    color: var(--black-clr);
    background-color: var(--white-clr);
    border-radius: 50%;
    display: grid;
    place-items: center;
    padding: 2px;
    cursor: pointer;
}
.addPostCardBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--dark-gray-clr);
    border-radius: 8px;
    padding: 8px 16px;
    margin-bottom: 16px;
}
.addPostCardBottomText {
    font-size: var(--fs-200);
    font-weight: 600;
    margin: 0;
}
.addPostCardBottomIcons {
    display: flex;
    gap: 4px;
}
.addPostCardBottomIcon {
    cursor: pointer;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: grid;
    place-items: center;
}
.addPostCardBottomIcons label {
    margin-top: 4px;
    padding: 0;
}
.addPostCardBottomIcon:hover {
    background-color: var(--gray-clr);
}
.addPostCardBtn {
    width: 100%;
    background-color: var(--blue-clr);
    color: var(--white-clr);
    border: none;
    height: 32px;
    border-radius: 8px;
    font-size: var(--fs-200);
    font-weight: 500;
    cursor: pointer;
}
.addPostCardBtn:hover {
    background-color: darkblue;
}
.addPostCardInput {
    display: none;
}
.addPostCardContainer input.formError {
    border: none !important;
    border-bottom: 1px solid red !important;
}